import * as ACTIONS from 'store/actions';

const initialState = {
    getPostsLoading: false,
    posts: [],
    gridPosts: [],
    gridPostsLoading: false,
    reviewedPosts: [],
    reviewedPostsLoading: false,
    reviewedPostsCount: 0,
};

const utilReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_POSTS_START:
            return {
                ...state,
                getPostsLoading: true
            };
        case ACTIONS.GET_POSTS_SUCCESS:
            return {
                ...state,
                getPostsLoading: false,
                posts: action.data
            };
        case ACTIONS.GET_POSTS_FAIL:
            return {
                ...state,
                getPostsLoading: false
            };

        case ACTIONS.GET_POSTS_GRID_START:
            return {
                ...state,
                gridPostsLoading: true
            };
        case ACTIONS.GET_POSTS_GRID_SUCCESS:
            return {
                ...state,
                gridPostsLoading: false,
                gridPosts:
                    action.data[0]?.page > 1
                        ? [...state.gridPosts, ...action.data]
                        : action.data,
            };
        case ACTIONS.GET_POSTS_GRID_FAIL:
            return {
                ...state,
                gridPostsLoading: false
            };


        case ACTIONS.GET_REVIEWED_POSTS_START:
            return {
                ...state,
                reviewedPostsLoading: true
            };
        case ACTIONS.GET_REVIEWED_POSTS_SUCCESS:
            return {
                ...state,
                reviewedPostsLoading: false,
                reviewedPostsCount: action.data.count,
                reviewedPosts:
                    action.data.page > 1
                        ? [...state.reviewedPosts, ...action.data.posts]
                        : action.data.posts,
            };
        case ACTIONS.GET_REVIEWED_POSTS_FAIL:
            return {
                ...state,
                reviewedPostsLoading: false
            };

        default:
            return state;
    }
};
export default utilReducer;

// assets
import {
    IconBriefcase,
    IconMessages,
    IconUsers,
    IconTools,
    IconLayoutGrid,
    IconMessageCircle2,
    IconBell,
    IconChartBar,
    IconReport,
    IconBuildingBank,
    IconDeviceTv,
    IconHeartHandshake,
} from '@tabler/icons';

const pages = {
    id: 'pages',
    title: '',
    type: 'group',
    breadcrumbs: false,
    children: [
        {
            id: 'insight',
            title: 'Insights',
            type: 'collapse',
            icon: IconChartBar,
            children: [
                {
                    id: 'userinsight',
                    title: 'Users Insight',
                    type: 'item',
                    url: '/insight/users',
                    breadcrumbs: false
                }, {
                    id: 'postinsight',
                    title: 'Posts Insight',
                    type: 'item',
                    url: '/insight/posts',
                    breadcrumbs: false
                }, {
                    id: 'discussioninsight',
                    title: 'Discussions Insight',
                    type: 'item',
                    url: '/insight/discussions',
                    breadcrumbs: false
                }, {
                    id: 'pushinsight',
                    title: 'Notification Insight',
                    type: 'item',
                    url: '/insight/push',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'posts',
            title: 'Posts',
            type: 'collapse',
            icon: IconLayoutGrid,
            children: [
                {
                    id: 'pdInsight',
                    title: 'Posts Design Insight',
                    type: 'item',
                    url: '/posts/pdInsight',
                    breadcrumbs: false
                },
                {
                    id: 'gPosts',
                    title: 'Posts Grid View',
                    type: 'item',
                    url: '/posts/gPosts',
                    breadcrumbs: false
                },
                {
                    id: 'pendingPost',
                    title: 'Review Pending',
                    type: 'item',
                    url: '/posts/pending',
                    breadcrumbs: false
                },
                {
                    id: 'grPosts',
                    title: 'Reviewed',
                    type: 'item',
                    url: '/posts/grPosts',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'discussions',
            title: 'Discussions',
            type: 'collapse',
            icon: IconMessages,
            children: [
                {
                    id: 'pendingDiscussion',
                    title: 'Review Pending',
                    type: 'item',
                    url: '/discussions/pending',
                    breadcrumbs: false
                },
                {
                    id: 'allDiscussion',
                    title: 'All Live',
                    type: 'item',
                    url: '/discussions/all',
                    breadcrumbs: false
                },
                {
                    id: 'reviewed',
                    title: 'Reviewed',
                    type: 'item',
                    url: '/discussions/reviewed',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'chats',
            title: 'Chats',
            type: 'item',
            icon: IconMessageCircle2,
            url: '/chats',
            breadcrumbs: false
        },
        {
            id: 'leadManagement',
            title: ' Lead Management',
            type: 'collapse',
            icon: IconBuildingBank,
            children: [
                {
                    id: 'leads',
                    title: 'Leads',
                    type: 'item',
                    url: '/leadManagement/leads',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'materialInventory',
            title: 'Material Inventory',
            type: 'collapse',
            icon: IconBuildingBank,
            children: [
                {
                    id: 'materialInventoryitems',
                    title: 'Materials',
                    type: 'item',
                    url: '/materialInventory/items',
                    breadcrumbs: false
                },
                {
                    id: 'leads',
                    title: 'Leads',
                    type: 'item',
                    url: '/materialInventory/leads',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'push',
            title: 'Push Notifications',
            type: 'item',
            icon: IconBell,
            url: '/push',
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            icon: IconReport,
            children: [
                {
                    id: 'reportedDiscussions',
                    title: 'Discussions',
                    type: 'item',
                    url: '/reports/discussions',
                    breadcrumbs: false
                },
                {
                    id: 'reportedPosts',
                    title: 'Posts',
                    type: 'item',
                    url: '/reports/posts',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: IconUsers,
            children: [
                {
                    id: 'allusers',
                    title: 'All',
                    type: 'item',
                    url: '/users/all',
                    breadcrumbs: false
                },
                {
                    id: 'reviewusers',
                    title: 'Review Auto Follow',
                    type: 'item',
                    url: '/users/review',
                    breadcrumbs: false
                },
                {
                    id: 'reviewuserstv',
                    title: 'Tv Users Auto Follow',
                    type: 'item',
                    url: '/users/reviewtv',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'tvPosts',
            title: 'Verko TV',
            type: 'item',
            icon: IconDeviceTv,
            url: '/tvposts',
            breadcrumbs: false
        },
        {
            id: 'inquiries',
            title: 'Inquiries',
            type: 'item',
            icon: IconHeartHandshake,
            url: '/inquiries',
            breadcrumbs: false
        },
        {
            id: 'utilities',
            title: 'Utilities',
            type: 'collapse',
            icon: IconTools,
            children: [
                {
                    id: 'lmActivity',
                    title: 'Lead Master Activity',
                    type: 'item',
                    url: '/utils/lmActivity',
                    breadcrumbs: false
                },
                {
                    id: 'postTags',
                    title: 'PostTags',
                    type: 'item',
                    url: '/utils/post-tags',
                    breadcrumbs: false
                },
                {
                    id: 'profession',
                    title: 'Professions',
                    type: 'item',
                    url: '/utils/profession',
                    breadcrumbs: false
                },
                {
                    id: 'professionServices',
                    title: 'Professions Services',
                    type: 'item',
                    url: '/utils/profession-services',
                    breadcrumbs: false


                },
                {
                    id: 'serviceLocations',
                    title: 'Service Locations',
                    type: 'item',
                    url: '/utils/service-locations',
                    breadcrumbs: false
                },
                {
                    id: 'serviceLocationszone',
                    title: 'Service Location Zones',
                    type: 'item',
                    url: '/utils/location-zones',
                    breadcrumbs: false
                },
                {
                    id: 'deeplinks',
                    title: 'Deep Links',
                    type: 'item',
                    url: '/utils/deepLinks',
                    breadcrumbs: false
                },
                {
                    id: 'banners',
                    title: 'Banners',
                    type: 'item',
                    url: '/utils/banners',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'admins',
            title: 'Admins',
            type: 'item',
            icon: IconBriefcase,
            url: '/admins',
            breadcrumbs: false
        },
    ]
};

export default pages;

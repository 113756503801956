import { useRoutes } from 'react-router-dom';

// routes
import Admin from './admin';
import AdminAuth from './adminAuth';
import config from '../config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminAuth, Admin], config.basename);
}

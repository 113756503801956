import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import utilReducer from './utilReducer';
import postReducer from './postReducer';
import discussionReducer from './discussionReducer';
import userReducer from './userReducer';
import chatReducer from './chatReducer';
import notificationReducer from './notificationReducer';
import tvPostReducer from './tvPostReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authReducer,
    utilReducer,
    discussionReducer,
    postReducer,
    userReducer,
    chatReducer,
    tvPostReducer,
    notificationReducer
});

export default reducer;

import { put, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from 'store/actions';

import Fetch from '../../network/Fetch';
import * as Constant from '../constant';

function* addProfession(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_PROFESSION, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_PROFESSION_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_PROFESSION_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_PROFESSION_FAIL });
    }
}
function* addPostTag(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_POST_TAGS, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_POST_TAGS_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_POST_TAGS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_POST_TAGS_FAIL });
    }
}
function* addProfessionService(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_PROFESSION_SERVICE, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_PROFESSION_SERVICE_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_PROFESSION_SERVICE_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_PROFESSION_SERVICE_FAIL });
    }
}
function* addServiceLocation(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_SERVICE_LOCATIONS, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_SERVICE_LOCATIONS_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_SERVICE_LOCATIONS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_SERVICE_LOCATIONS_FAIL });
    }
}
function* addLoanProvider(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_LOAN_PROVIDER, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_LOAN_PROVIDER_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_LOAN_PROVIDER_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_LOAN_PROVIDER_FAIL });
    }
}

function* getPostTags(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_POST_TAGS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_POST_TAGS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_POST_TAGS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_POST_TAGS_FAIL });
    }
}

function* getLoanProviders(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_LOAN_PROVIDERS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_LOAN_PROVIDERS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_LOAN_PROVIDERS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_LOAN_PROVIDERS_FAIL });
    }
}

function* getProfessions(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_PROFESSIONS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_PROFESSIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_PROFESSIONS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_PROFESSIONS_FAIL });
    }
}

function* getProfessionsServices(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_PROFESSION_SERVICES, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_PROFESSION_SERVICES_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_PROFESSION_SERVICES_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_PROFESSION_SERVICES_FAIL });
    }
}

function* getServiceLocations(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_SERVICE_LOCATIONS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_SERVICE_LOCATIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_SERVICE_LOCATIONS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_SERVICE_LOCATIONS_FAIL });
    }
}

function* getLeads(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_LEADS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_LEADS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_LEADS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_LEADS_FAIL });
    }
}

function* getLeadDetails(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_LEAD_DETAILS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_LEAD_DETAILS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_LEAD_DETAILS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_LEAD_DETAILS_FAIL });
    }
}

function* getReports(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_REPORTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_REPORTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_REPORTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_REPORTS_FAIL });
    }
}

function* updateReportStatus(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.UPDATE_REPORT_STATUS, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.UPDATE_REPORT_STATUS_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.UPDATE_REPORT_STATUS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.UPDATE_REPORT_STATUS_FAIL });
    }
}

function* addleadActivity(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_LEAD_ACTIVITY, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_LEAD_ACTIVITY_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_LEAD_ACTIVITY_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_LEAD_ACTIVITY_FAIL });
    }
}

function* deleteleadStatus(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.DELETE_LEAD_STATUS, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.DELETE_LEAD_STATUS_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.DELETE_LEAD_STATUS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.DELETE_LEAD_STATUS_FAIL });
    }
}

function* updateLeadStatus(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.UPDTAE_LEAD_ACTIVITY_STATUS, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.UPDTAE_LEAD_ACTIVITY_STATUS_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.UPDTAE_LEAD_ACTIVITY_STATUS_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.UPDTAE_LEAD_ACTIVITY_STATUS_FAIL });
    }
}

function* getInsight(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_INSIGHTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_INSIGHTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_INSIGHTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_INSIGHTS_FAIL });
    }
}

function* createZone(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.CREATE_ZONE, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.CREATE_ZONE_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.CREATE_ZONE_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.CREATE_ZONE_FAIL });
    }
}

function* getZones(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_ZONES, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_ZONES_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_ZONES_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_ZONES_FAIL });
    }
}

function* createLeadMasterActivity(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.CREATE_LEAD_MASTER_ACTIVITY, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.CREATE_LEAD_MASTER_ACTIVITY_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.CREATE_LEAD_MASTER_ACTIVITY_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.CREATE_LEAD_MASTER_ACTIVITY_FAIL });
    }
}

function* getLeadMasterActivity(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_LEAD_MASTER_ACTIVITY, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_LEAD_MASTER_ACTIVITY_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_LEAD_MASTER_ACTIVITY_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_LEAD_MASTER_ACTIVITY_FAIL });
    }
}



function* addInventoryItem(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_INVENTORY_ITEM, action.params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_INVENTORY_ITEM_SUCCESS });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_INVENTORY_ITEM_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_INVENTORY_ITEM_FAIL });
    }
}

function* getInventoryItems(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_INVENTORY_ITEMS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_INVENTORY_ITEMS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_INVENTORY_ITEMS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_INVENTORY_ITEMS_FAIL });
    }
}

function* getInventoryLeads(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_INVENTORY_LEADS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_INVENTORY_LEADS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_INVENTORY_LEADS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_INVENTORY_LEADS_FAIL });
    }
}

function* getPdInsight(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_POST_DESIGN_INSIGHTS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_POST_DESIGN_INSIGHTS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_POST_DESIGN_INSIGHTS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_POST_DESIGN_INSIGHTS_FAIL });
    }
}

function* getInquiries(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_INQUIRIES, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_INQUIRIES_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_INQUIRIES_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_INQUIRIES_FAIL });
    }
}


function* shortenDeepLink(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.SHORTEN_DEEPLINK, params);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.SHORTEN_DEEPLINK_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.SHORTEN_DEEPLINK_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.SHORTEN_DEEPLINK_FAIL });
    }
}


function* getDeepLinks(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_DEEPLINKS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_DEEPLINKS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_DEEPLINKS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_DEEPLINKS_FAIL });
    }
}

function* addBanner(action) {
    try {
        const params = action.params;
        const images = action.images;
        const response = yield call(new Fetch().axiosFormDataRequest, 'POST', Constant.ADD_BANNER, params, images);
        if (response.status === 200) {
            action.callBack('success', 'Sucess');
            yield put({ type: ACTION.ADD_BANNER_SUCCESS, data: response.data });
        } else {
            action.callBack('error', 'Error Occured!');
            yield put({ type: ACTION.ADD_BANNER_FAIL });
        }
    } catch (err) {
        action.callBack('error', 'Error Occured!');
        yield put({ type: ACTION.ADD_BANNER_FAIL });
    }
}


function* getBanners(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_BANNERS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_BANNERS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_BANNERS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_BANNERS_FAIL });
    }
}

export function* watchAddInventoryItem() {
    yield takeEvery(ACTION.ADD_INVENTORY_ITEM_START, addInventoryItem);
}

export function* watchGetInventoryItems() {
    yield takeEvery(ACTION.GET_INVENTORY_ITEMS_START, getInventoryItems);
}

export function* watchGetInventoryLeads() {
    yield takeEvery(ACTION.GET_INVENTORY_LEADS_START, getInventoryLeads);
}

export function* watchGetLeads() {
    yield takeEvery(ACTION.GET_LEADS_START, getLeads);
}

export function* watchGetLeadDetails() {
    yield takeEvery(ACTION.GET_LEAD_DETAILS_START, getLeadDetails);
}

export function* watchUpdateLeadStatus() {
    yield takeEvery(ACTION.UPDTAE_LEAD_ACTIVITY_STATUS_START, updateLeadStatus);
}
export function* watchDeleteleadStatus() {
    yield takeEvery(ACTION.DELETE_LEAD_STATUS_START, deleteleadStatus);
}
export function* watchAddleadActivity() {
    yield takeEvery(ACTION.ADD_LEAD_ACTIVITY_START, addleadActivity);
}

export function* watchGetLoanProviders() {
    yield takeEvery(ACTION.GET_LOAN_PROVIDERS_START, getLoanProviders);
}

export function* watchGetProfessions() {
    yield takeEvery(ACTION.GET_PROFESSIONS_START, getProfessions);
}
export function* watchGetProfessionsServices() {
    yield takeEvery(ACTION.GET_PROFESSION_SERVICES_START, getProfessionsServices);
}
export function* watchGetServiceLocations() {
    yield takeEvery(ACTION.GET_SERVICE_LOCATIONS_START, getServiceLocations);
}
export function* watchGetPostTags() {
    yield takeEvery(ACTION.GET_POST_TAGS_START, getPostTags);
}

export function* watchAddProfession() {
    yield takeEvery(ACTION.ADD_PROFESSION_START, addProfession);
}
export function* watchAddPostTag() {
    yield takeEvery(ACTION.ADD_POST_TAGS_START, addPostTag);
}
export function* watchAddProfessionService() {
    yield takeEvery(ACTION.ADD_PROFESSION_SERVICE_START, addProfessionService);
}
export function* watchAddServiceLocation() {
    yield takeEvery(ACTION.ADD_SERVICE_LOCATIONS_START, addServiceLocation);
}
export function* watchAddLoanProvider() {
    yield takeEvery(ACTION.ADD_LOAN_PROVIDER_START, addLoanProvider);
}

export function* watchGetReports() {
    yield takeEvery(ACTION.GET_REPORTS_START, getReports);
}

export function* watchUpdateReportStatus() {
    yield takeEvery(ACTION.UPDATE_REPORT_STATUS_START, updateReportStatus);
}

export function* watchGetInsight() {
    yield takeEvery(ACTION.GET_INSIGHTS_START, getInsight);
}

export function* watchGetPdInsight() {
    yield takeEvery(ACTION.GET_POST_DESIGN_INSIGHTS_START, getPdInsight);
}

export function* watchCreateZone() {
    yield takeEvery(ACTION.CREATE_ZONE_START, createZone);
}

export function* watchGetZones() {
    yield takeEvery(ACTION.GET_ZONES_START, getZones);
}

export function* watchCreateLeadMasterActivity() {
    yield takeEvery(ACTION.CREATE_LEAD_MASTER_ACTIVITY_START, createLeadMasterActivity);
}

export function* watchGetLeadMasterActivity() {
    yield takeEvery(ACTION.GET_LEAD_MASTER_ACTIVITY_START, getLeadMasterActivity);
}

export function* watchGetInquiries() {
    yield takeEvery(ACTION.GET_INQUIRIES_START, getInquiries);
}

export function* watchShortenDeepLink() {
    yield takeEvery(ACTION.SHORTEN_DEEPLINK_START, shortenDeepLink);
}

export function* watchGetDeepLinks() {
    yield takeEvery(ACTION.GET_DEEPLINKS_START, getDeepLinks);
}

export function* watchAddBanner() {
    yield takeEvery(ACTION.ADD_BANNER_START, addBanner);
}

export function* watchGetBanners() {
    yield takeEvery(ACTION.GET_BANNERS_START, getBanners);
}
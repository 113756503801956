import moment from "moment";

const USER_GENDER = [
    {
        label: 'Male',
        value: 0
    },
    {
        label: 'Female',
        value: 1
    },
    {
        label: 'Non-binary',
        value: 2
    }
];

const USER_STATUS = [
    {
        label: 'Active',
        value: 1
    },
    {
        label: 'Inactive',
        value: 2
    },
    {
        label: 'Blocked',
        value: 3
    }
];


const USER_TYPE = [
    {
        label: 'Admin',
        value: 1
    },
    {
        label: 'Home Owner',
        value: 2
    },
    {
        label: 'Business User',
        value: 3
    }
];
export const PROFESSION_TYPE = {
    SERVICE_PROVIDER: 1,
    MATERIAL_SUPPLIER: 2
}

export const getAdminRole = () => {
    const admin = localStorage.getItem('admin');
    if (admin) {
        const unstringfyData = JSON.parse(admin);
        const { role } = unstringfyData;
        return role;
    }
    return -1;
}

export const getAdminEmail = () => {
    const admin = localStorage.getItem('admin');
    if (admin) {
        const unstringfyData = JSON.parse(admin);
        const { email } = unstringfyData;
        return email;
    }
    return null;
}

export const GET_GENDER = (value) => {
    if (!Number.isNaN(value) && value > -1 && value < 4) {
        return `${USER_GENDER[value].label}`;
    }
    return '';
};

export const GET_USER_STATUS = (value) => {
    if (!Number.isNaN(value)) {
        return `${USER_STATUS[value].label}`;
    }
    return '';
};


export const GET_USER_TYPE = (value) => {
    if (!Number.isNaN(value) && value > -1 && value < 3) {
        return `${USER_TYPE[value].label}`;
    }
    return '';
};

export const USER_TYPES = ['Admin', 'Home Owner', 'Business']
export const USER_STATUSES = ['Active', 'Inactive', 'Blocked']
export const USER_PROGRESS = ['SignUp', 'User Type', 'Profession', 'Basic']

// password strength indicator
export const USER_TABLE_COLUMNS = [
    { id: '_id', label: '_id', format: (value) => value },
    { id: 'phone', label: 'Phone', format: (value) => value },
    { id: 'firstName', label: 'First Name', format: (value) => value },
    { id: 'lastName', label: 'Last Name', format: (value) => value },
    { id: 'status', label: 'Status', format: (value) => USER_STATUSES[value - 1] },
    // { id: 'userType', label: 'User Type', format: (value) => USER_TYPES[value - 1] },
    { id: 'tester', label: 'Tester', format: (value) => value ? JSON.stringify(value) : 'false' },
    { id: 'verkoUser', label: 'Verko User', format: (value) => value ? JSON.stringify(value) : 'false' },
    { id: 'progress', label: 'Progress', format: (value) => USER_PROGRESS[value - 1] },
    // { id: 'followers', label: 'Followers', format: (value) => value },
    // { id: 'following', label: 'Followings', format: (value) => value },
    // { id: 'postCount', label: 'Posts', format: (value) => value },
];

export const USER_FOR_FOLLOW_TABLE_COLUMNS = [
    { id: '_id', label: '_id', format: (value) => value },
    // { id: 'postCount', label: 'Posts', format: (value) => value },
    { id: 'postCount', label: 'Posts', format: (value) => value },
    { id: 'followers', label: 'Followers', format: (value) => value },
    { id: 'following', label: 'Followings', format: (value) => value },
    { id: 'qualityScore', label: 'Score', format: (value) => value || 0 },
];
export const USERTV_FOR_FOLLOW_TABLE_COLUMNS = [
    { id: '_id', label: '_id', format: (value) => value },
    { id: 'firstName', label: 'Name', format: (value) => value },
    { id: 'tvPostCount', label: 'Posts', format: (value) => value },
    { id: 'followers', label: 'Followers', format: (value) => value },
    { id: 'following', label: 'Followings', format: (value) => value },
    { id: 'qualityScore', label: 'Score', format: (value) => value || 0 },
    { id: 'qualityScoreUpdateAt', label: 'QS Updated At', format: (value) => value ? moment(value, 'x').format('DD/MM/YYYY') : '' },
];

export const ADMIN_TABLE_COLUMNS = [
    { id: '_id', label: '_id', format: (value) => value },
    // { id: 'name', label: 'Name', format: (value) => value },
    { id: 'email', label: 'Email', format: (value) => value },
    { id: 'phone', label: 'Phone', format: (value) => value },
    { id: 'role', label: 'Role', format: (value) => value === 2 ? 'PROJECT_MANAGER' : 'TEAM_MEMBER' },
];
